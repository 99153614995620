import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';

import Block from './Block';

import { Wrapper } from './styles';

const AboutTemplate = ({
  data: { datoCmsAboutPage: pageData },
  pageContext,
}) => {
  const { aboutBlocks } = pageData;
  return (
    <PageWrapper pageData={pageContext}>
      <MainContainer>
        <Wrapper>
          {aboutBlocks.map((block, index) => {
            const { title, description, image } = block;
            return (
              <Block
                key={index}
                index={index}
                title={title}
                description={description}
                image={image}
              />
            );
          })}
        </Wrapper>
      </MainContainer>
    </PageWrapper>
  );
};

export default AboutTemplate;

export const query = graphql`
  query AboutQuery($locale: String!) {
    datoCmsAboutPage(locale: { eq: $locale }) {
      aboutBlocks {
        title: aboutBlockTitle
        description: aboutBlockDescription {
          value
        }
        image: aboutBlockImage {
          gatsbyImageData
        }
      }
    }
  }
`;
