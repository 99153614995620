import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.section`
  flex-direction: ${(props) => props.isOdd && 'row-reverse'};
  column-gap: 6.4375rem;
  align-items: center;
  display: flex;

  ${media.tabletS`
    display: grid;
  `}
`;
const ImageWrapper = styled.div`
  width: 45%;

  ${media.tabletS`
    margin: var(--gap) 0;
    width: 100%;
  `}
`;

const Info = styled.div`
  display: grid;
  row-gap: 1rem;
  width: 55%;

  ${media.tabletS`
    width: 100%;
  `}
`;

const Description = styled.div`
  font-size: 1.125rem;
  line-height: 2rem;
`;

export { Wrapper, ImageWrapper, Info, Description };
