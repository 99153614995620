import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.section`
  display: grid;
  row-gap: 5.875rem;

  ${media.mobileM`
    row-gap: 4.5625rem;
  `}
`;

export { Wrapper };
