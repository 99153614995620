import { StructuredText } from 'react-datocms';

import { HeadingMedium } from 'components/UI/Headings';

import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from 'styles/elements';

import Image from 'components/Image';

import { Wrapper, ImageWrapper, Info, Description } from './styles';

const Block = ({ title, description, image, index }) => {
  const isOdd = index % 2 !== 0;

  return (
    <Wrapper isOdd={isOdd}>
      <ShowOnlyOnMobile>
        <HeadingMedium isCenter isDivider>
          {title}
        </HeadingMedium>
      </ShowOnlyOnMobile>
      <ImageWrapper>
        <Image image={image} alt={title} />
      </ImageWrapper>
      <Info>
        <ShowOnlyOnDesktop>
          <HeadingMedium isDivider>{title}</HeadingMedium>
        </ShowOnlyOnDesktop>
        <Description>
          <StructuredText data={description} />
        </Description>
      </Info>
    </Wrapper>
  );
};

export default Block;
